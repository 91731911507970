import React from "react";
import classNames from "classnames";

import "./Tabs.scss";

const Tabs = ({ tabs, color, selectedTab }) => {
  const TabClass = (key) =>
    classNames("Tabs-unselected", { "Tabs-selected": selectedTab === key });

  return (
    <div className="Tabs-container" style={{ color }}>
      {tabs.map((tab) => (
        <span
          key={tab.text}
          className={TabClass(tab.key)}
          onClick={tab.onClick}
        >
          {tab.text.toUpperCase()}
        </span>
      ))}
    </div>
  );
};

export default Tabs;
