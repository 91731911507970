import React from "react";
import { InstagramLogo } from "phosphor-react";

import { openLink } from "../../utils/clickHandlers";

import "./Footer.scss";

const links = [
  {
    text: "Espace client",
    onClick: openLink("https://kargoservice.everst.io/client/login"),
  },
  {
    text: "Mentions légales",
    onClick: openLink("https://kargoservice.everst.io/client/login"),
  },
  {
    text: "Politique de confidentialité",
    onClick: openLink("https://kargoservice.everst.io/client/login"),
  },
];

const Footer = () => {
  return (
    <footer className="Footer-container">
      <div className="Footer-content">
        <div className="desktop-only">© Kargo 2024</div>
        <div className="Footer-links">
          {links.map((link) => (
            <span className="link" onClick={link.onClick} key={link.text}>
              {link.text}
            </span>
          ))}
        </div>
        <div className="Footer-socials">
          <InstagramLogo
            size={32}
            className="link"
            onClick={openLink("https://www.instagram.com/kargobikeservice/")}
          />
          <div className="mobile-only">© Kargo 2024</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
