import React from "react";
import classNames from "classnames";

import "./Button.scss";

const Button = ({ type = "button", className, text, onClick, dark }) => {
  const ButtonClass = classNames("Button-container", {
    "Button-dark": dark,
  });

  return (
    <button
      className={`${className} ${ButtonClass}`}
      type={type}
      onClick={onClick}
    >
      <span>{text}</span>
    </button>
  );
};

export default Button;
