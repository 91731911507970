import React, { useEffect } from "react";

import Title from "../../components/title/Title";

import "./Contact.scss";

const Contact = () => {
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "https://kargoservice.everst.io/js/forms.js";
  //   script.async = true;
  //   const initializeForm = () => {
  //     const formContainer = document.querySelector(".everest-form");
  //     if (formContainer) {
  //       console.log(window);
  //       window.everest_form(formContainer);
  //     }
  //   };
  //   document.body.appendChild(script);
  //   script.onload = initializeForm;
  //   return () => document.body.removeChild(script);
  // }, []);

  return (
    <section className="Contact-container">
      <div className="Contact-content">
        <Title
          title="nous contacter"
          // subtitle="Une question ? Une demande spécifique ? Envie d'en savoir plus ? Envoyez-nous simplement un message via ce formulaire."
          subtitle="Une question ? Une demande spécifique ? Envie d'en savoir plus ? Envoyez-nous simplement un message."
        />
      </div>
      <div>
        <a href="mailto:contact@kargobikeservice.com">
          contact@kargobikeservice.com
        </a>
      </div>
      {/* <div className="Contact-form">
        <script src="https://kargoservice.everst.io/js/forms.js"></script>
        <div className="everest-form" data-ref="SSP86L4BYNUOBM3IJH"></div>
      </div> */}
    </section>
  );
};

export default Contact;
