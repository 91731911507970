import React, { useEffect, useLayoutEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import { InstagramLogo } from "phosphor-react";

import { useMenuContext } from "../../contexts/MenuProvider";
import { openLink } from "../../utils/clickHandlers";
import { activateScroll, deactivateScroll } from "../../utils/disableScroll";

import "./Menu.scss";

const Menu = ({ links }) => {
  const { setIsMenuOpened } = useMenuContext();

  const moutingPoint = useMemo(() => document.createElement("section"), []);

  useLayoutEffect(() => {
    document.body.appendChild(moutingPoint);
    return () => document.body.removeChild(moutingPoint);
  }, [moutingPoint]);

  const handleClickOnLink = (action) => {
    action();
    setIsMenuOpened(false);
  };

  useEffect(() => {
    deactivateScroll();
    return activateScroll;
  }, []);

  return (
    <>
      {ReactDOM.createPortal(
        <div className="Menu-container">
          <div className="Menu-content">
            <ul className="Menu-links">
              {links.map((link, i) => (
                <li key={i}>
                  <span className="Menu-number">{i + 1}</span>
                  <p
                    className="Menu-link"
                    onClick={() => handleClickOnLink(link.onClick)}
                  >
                    {link.text}
                  </p>
                </li>
              ))}
            </ul>
            <div>
              <InstagramLogo
                size={48}
                onClick={() =>
                  handleClickOnLink(
                    openLink("https://www.instagram.com/kargobikeservice/")
                  )
                }
              />
            </div>
          </div>
        </div>,
        moutingPoint
      )}
    </>
  );
};

export default Menu;
