import React, { useContext, useState } from "react";

const VIDEO_LOADING_CONTEXT = React.createContext(null);

const useVideoLoadingContext = () => useContext(VIDEO_LOADING_CONTEXT);

const VideoLoadingProvider = ({ children }) => {
  const [isVideoLoading, setIsVideoLoading] = useState(true);

  return (
    <VIDEO_LOADING_CONTEXT.Provider
      value={{ isVideoLoading, setIsVideoLoading }}
    >
      {children}
    </VIDEO_LOADING_CONTEXT.Provider>
  );
};

export { VideoLoadingProvider, useVideoLoadingContext };
