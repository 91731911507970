import React from "react";
import ReactDOM from "react-dom/client";
import { Analytics } from "@vercel/analytics/react";

import { MenuProvider } from "./contexts/MenuProvider";
import { RefProvider } from "./contexts/RefProvider";
import { VideoLoadingProvider } from "./contexts/VideoLoadingProvider";
import reportWebVitals from "./reportWebVitals";

import App from "./App";

import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RefProvider>
      <MenuProvider>
        <VideoLoadingProvider>
          <App />
          <Analytics />
        </VideoLoadingProvider>
      </MenuProvider>
    </RefProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
