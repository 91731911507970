import React, { useContext, useState } from "react";

const MENU_CONTEXT = React.createContext(null);

const useMenuContext = () => useContext(MENU_CONTEXT);

const MenuProvider = ({ children }) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  return (
    <MENU_CONTEXT.Provider value={{ isMenuOpened, setIsMenuOpened }}>
      {children}
    </MENU_CONTEXT.Provider>
  );
};

export { MenuProvider, useMenuContext };
