import React from "react";

import classNames from "classnames";

import { capitalize } from "../../utils/capitalize";

import "./Title.scss";

const Title = ({ title, subtitle, color, centered }) => {
  const TitleClass = classNames("Title-container", {
    "Title-container-centered": centered,
  });

  return (
    <article className={TitleClass} style={{ color }}>
      <h1 className="Title-content">{capitalize(title)}</h1>
      {!!subtitle && <p className="Title-subtitle">{subtitle}</p>}
    </article>
  );
};

export default Title;
