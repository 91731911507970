import React from "react";

import "./CardExplainer.scss";

const CardExplainer = ({ title, text }) => {
  return (
    <div className="CardExplainer-container">
      <h1 className="CardExplainer-title">{title}</h1>
      <p className="CardExplainer-text">{text}</p>
    </div>
  );
};

export default CardExplainer;
